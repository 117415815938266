<template>
  <div
    class="full-height d-flex flex-column"
  >
    <v-img
      src="/mm_logo_transparent_tall.png"
      width="64px"
      style="position: absolute; top: 24px; right: 24px;"
    />

    <div
      v-if="$wait.is('loading')"
      class="full-height mt-16"
    >
      <m-loader-circular
        text="Vahvistetaan aikataulua..."
        :size="20"
        :width="2"
      />
    </div>

    <div
      v-else-if="error"
      class="d-flex flex-column full-height mx-auto text-center mt-16 pt-16"
    >
      <div
        class="d-flex flex-column"
      >
        <v-icon
          color="deep-orange"
          x-large
        >
          warning
        </v-icon>

        <div
          class="mt-2 text-uppercase caption font-weight-bold"
        >
          Projektia ei löytynyt
        </div>
      </div>
    </div>

    <div
      v-else
      class="d-flex flex-column full-height mx-auto text-center mt-16 pt-16"
    >
      <div
        class="d-flex flex-column"
      >
        <v-icon
          color="secondary"
          x-large
        >
          done_all
        </v-icon>

        <div
          class="mt-2 text-uppercase caption font-weight-bold"
        >
          Kiitos
        </div>
      </div>

      <div
        class="mt-14"
      >
        Kohteen {{ project.pid }}
        <br>
        aikataulu vahvistettu.

        <br><br>

        <span
          class="caption"
        >
          Voit sulkea tämän sivun.
        </span>
      </div>
    </div>

    <footer
      class="primary white--text py-12"
      app
    >
      <div
        class="text-center mx-auto"
      >
        <div
          class="mb-6"
        >
          Mestamaster Oy &copy; {{ new Date().getFullYear() }} &#x2022; 2972171-7
        </div>

        <div
          class="caption"
        >
          Tykistökatu 4B, 20520 Turku
        </div>

        <div>
          <a
            href="tel:+358449135409"
            class="white--text caption"
            :style="{
              textDecoration: 'none',
            }"
          >
            +358 44 913 5409
          </a>
        </div>

        <a
          href="mailto:info@mestamaster.fi"
          target="_blank"
          class="white--text caption"
          :style="{
            textDecoration: 'none',
          }"
        >
          info@mestamaster.fi
        </a>
      </div>
    </footer>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    props: {
      cid: {
        type: String,
        required: true,
      },
    },

    data: () => ({
      error: false,
    }),

    computed: {
      ...mapGetters({
        scheduleConfirm: 'project/scheduleConfirms/scheduleConfirm',
        project: 'project/project/project',
      }),
    },

    async created() {
      this.$wait.start('loading');

      const success = await this.confirmSchedule({ cid: this.cid });
      await this.loadProject({ id: this.scheduleConfirm.projectId });

      if (success) {
        this.$mixpanel.trackEvent('Prepare Project Preconditions', {
          step: 'Confirm Schedule',
          projectId: this.$projectId,
          cid: this.cid,
          scheduleSentAt: this.scheduleConfirm.sentAt,
          scheduleConfirmedAt: this.scheduleConfirm.scheduleConfirmedAt,
          teamId: this.scheduleConfirm.teamId,
          receiverId: this.scheduleConfirm.receiverId,
        });
      } else {
        this.error = true;
      }

      this.$wait.end('loading');
    },

    methods: {
      ...mapActions({
        confirmSchedule: 'project/scheduleConfirms/confirmSchedule',
        loadProject: 'project/project/loadProject',
      }),
    },
  };
</script>
